<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 d-flex">
        <div class="wrapper w-100 m-auto pb-5">
          <div class="px-4 mb-5">
            <p class="titulo-pag">Atores</p>
            <h1 class="mt-3">Editar ator</h1>
          </div>
          <div class="text-center">
            <button v-if="tipo === 'fisico'" class="btn btn-primary mx-1 mb-5 px-4 py-2 rounded-pill" type="button" disabled="true">
              Pessoa física
            </button>
            <button v-if="tipo === 'juridico'" class="btn btn-primary mx-1 mb-5 px-4 py-2 rounded-pill" type="button" disabled="true">
              Pessoa Jurídica
            </button>
          </div>

          <FormAtorFisico v-if="tipo === 'fisico'" :registry="registry" />
          <FormAtorJuridico v-if="tipo === 'juridico'" :registry="registry" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormAtorFisico from './FormAtorFisico.vue';
import FormAtorJuridico from './FormAtorJuridico.vue';
import AtoresRepository from '../../libs/repositories/AtoresRepository.js';

export default {
  name: 'AtoresEdicao',
  data () {
    return {
      registry: null,
      tipo: null
    };
  },
  components: {
    FormAtorFisico,
    FormAtorJuridico
  },
  async created () {
    const id = +this.$route.params.id;

    const registry = await AtoresRepository.getById(id);

    if (registry) {
      this.tipo = registry.tipo_do_ator;
      this.registry = registry;
    }
  }
};

</script>
<style scoped>
  .titulo-pag{
    font-size: 14px;
    font-weight:600;
    color: #0A85CA;
  }
  h1{
    font-size: 22px;
    font-weight: bolder;
  }
  h1+span{
    font-size: 14px;
    color: #717171;
  }
</style>
